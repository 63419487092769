import { NgModule, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule, CustomFieldControl, CustomFieldConfigType, registerFormInputComponent } from '@vendure/admin-ui/core';
const Nepal = require('nepal-js');

@Component({
  template: `
	
	<select [formControl]="formControl">
     <option *ngFor="let type of types; let i = index" [value]="types[i]">{{types[i]}}</option>
    </select>
   `
})


export class DistrictControl implements CustomFieldControl {
  readonly: boolean;
  config: CustomFieldConfigType;
  formControl: FormControl;
  types: any = this.getDistricts();

  getDistricts(){
    let districts = Object.keys(Nepal.Districts);
    return districts;
  }

}

@NgModule({
  imports: [SharedModule],
  declarations: [DistrictControl],
  providers: [
      registerFormInputComponent('district-input',DistrictControl)
  ]
})

export class DistrictFieldModule { }