import { NgModule } from '@angular/core';
import { SharedModule, addActionBarItem, addNavMenuSection } from '@vendure/admin-ui/core';

@NgModule({
  imports: [SharedModule],
  providers: [
  /*
	 addNavMenuItem(
        {
          id: 'mails',
          label: 'Mails',
          routerLink: ['/extensions/subscribedmails'],
          icon: 'envelope',
          requiresPermission: 'SuperAdmin',
        },
        'subscriptions',
     ),
    addNavMenuItem(
      {
        id: 'phones',
        label: 'Phones',
        routerLink: ['/extensions/subscribedphones'],
        icon: 'phone-handset',
        requiresPermission: 'SuperAdmin',
      },
      'subscriptions',
   ),*/
   addNavMenuSection({
    id: 'subscriptions',
    label: 'Subscriptions',
    items:[{
        id: 'mails',
        label: 'Mails',
        routerLink: ['/extensions/subscribedmails'],
        icon: 'envelope',
        requiresPermission: 'SuperAdmin',
    },{
      id: 'phones',
      label: 'Phones',
      routerLink: ['/extensions/subscribedphones'],
      icon: 'phone-handset',
      requiresPermission: 'SuperAdmin',
    }],
    requiresPermission: 'SuperAdmin'
   }),

   addNavMenuSection({
    id: 'vendors',
    label: 'Vendors',
    items:[{
        id: 'vendors',
        label: 'Vendors',
        routerLink: ['/extensions/vendors'],
        icon: 'id-badge',
        requiresPermission: 'SuperAdmin',
    }],
    requiresPermission: 'SuperAdmin'
   }),

   addNavMenuSection({
    id: 'feedbacks',
    label: 'Feedbacks',
    items:[{
        id: 'feedbacks',
        label: 'Feedbacks',
        routerLink: ['/extensions/feedbacks'],
        icon: 'folder-open',
        requiresPermission: 'SuperAdmin',
    }],
    requiresPermission: 'SuperAdmin'
   }),

   addNavMenuSection({
    id: 'profile',
    label: 'Profile',
    items:[{
        id: 'profile',
        label: 'Profile',
        routerLink: ['/settings/profile'],
        icon: 'user',
        requiresPermission: 'SuperAdmin',
    }],
    requiresPermission: 'SuperAdmin',
   },"settings"),
  ]
})
export class NavBarHidingModule {}