<button
    class="btn btn-link btn-sm"
    (click)="toggleList()"
>
    <clr-icon shape="view-list"></clr-icon>
    {{ showList ? ('favorites.hide-favorites' | translate) : ('favorites.show-favorites' | translate) }}
</button>

<div class="favorites" *ngIf="showList">
    <div class="favorites-header">
        <input
            type="text"
            [placeholder]="'catalog.filter-by-name' | translate"
            class="clr-input"
            [formControl]="filterTermControl"
        />
    </div>
    <vdr-data-table
        [items]="favorites$ | async"
        [itemsPerPage]="favoritesItemsPerPage$ | async"
        [totalItems]="favoritesTotalItems$ | async"
        [currentPage]="favoritesCurrentPage$ | async"
        (pageChange)="setFavoritesPageNumber($event)"
        (itemsPerPageChange)="setFavoritesItemsPerPage($event)"
    >
        <ng-template let-product="item.product">
            <td class="left align-middle">
                <div class="image-placeholder">
                    <img
                        *ngIf="product.featuredAsset as asset; else imagePlaceholder"
                        [src]="asset | assetPreview:'tiny'"
                    />
                    <ng-template #imagePlaceholder>
                        <div class="placeholder"><clr-icon shape="image" size="48"></clr-icon></div>
                    </ng-template>
                </div>
            </td>
            <td class="left align-middle">
                {{ product.name }}
            </td>
        </ng-template>
    </vdr-data-table>
</div>
