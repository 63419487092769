import { NgModule, Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedModule, CustomFieldControl, CustomFieldConfigType, registerFormInputComponent } from '@vendure/admin-ui/core';


@Component({
  template: `
    <input type="text" [formControl]="formControl" disabled/>
   `
})

export class VisitNumberControl implements CustomFieldControl {
  readonly: boolean;
  config: CustomFieldConfigType;
  formControl: FormControl;
}

@NgModule({
  imports: [SharedModule],
  declarations: [VisitNumberControl],
  providers: [
      registerFormInputComponent('visitnumber-form-input',VisitNumberControl)
  ]
})

export class VisitNumberFieldControlModule { }