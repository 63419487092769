export const extensionRoutes = [  {
    path: 'extensions/subscribedmails',
    loadChildren: () => import('./extensions/54cc52c0b881fac0661e8b2384b6aecd8e119d6a613d9e69077d234f519c4e9e/mailsubscription-ui-lazy.module').then(m => m.MailSubscriptionUIModule),
  },
  {
    path: 'extensions/subscribedphones',
    loadChildren: () => import('./extensions/54cc52c0b881fac0661e8b2384b6aecd8e119d6a613d9e69077d234f519c4e9e/phonesubscription-ui-lazy.module').then(m => m.PhoneSubscriptionUIModule),
  },
  {
    path: 'extensions/vendors',
    loadChildren: () => import('./extensions/b72a4a4f3aaa937d18a9015d2c944a8bf9220931ac65896c641839cc7fdaa1d6/vendor-ui-lazy.module').then(m => m.VendorUIModule),
  },
  {
    path: 'extensions/feedbacks',
    loadChildren: () => import('./extensions/30a77b9eab9927e7c1ff256a732d8947cb6dfb1361dd47bd89ac4c4a56b555d3/feedback-ui-lazy.module').then(m => m.FeedbackUIModule),
  },
  {
    path: 'extensions/invoices',
    loadChildren: () => import('./extensions/251f61252b5b213f3d618b57754396e88ee34bf475396b776f672458d8b9cc3a/invoices.module').then(m => m.InvoicesModule),
  },
  {
    path: 'extensions/export-orders',
    loadChildren: () => import('./extensions/c29e4506e91cecae37926c1a2a26bca9b3138d5a9f8721e861927f1da81242ce/order-export.module').then(m => m.OrderExportModule),
  },
  {
    path: 'extensions/product-reviews',
    loadChildren: () => import('./extensions/4370f5c19e89b4a5606a654ac18d3ce01c92a1142fb9d18a0011dad2f13a6de1/reviews-ui-lazy.module').then(m => m.ReviewsUiLazyModule),
  }];
