import { NgModule, Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedModule, CustomFieldControl, CustomFieldConfigType, registerFormInputComponent } from '@vendure/admin-ui/core';


@Component({
  template: `
	
	<select [formControl]="formControl">
     <option *ngFor="let type of types; let i = index" [value]="types[i]">{{types[i]}}</option>
    </select>
	
   `
})


export class DiscountTypeControl implements CustomFieldControl {
  readonly: boolean;
  config: CustomFieldConfigType;
  types: any = ['Amount','Percentage','New Price'];
  formControl: FormControl;
}

@NgModule({
  imports: [SharedModule],
  declarations: [DiscountTypeControl],
  providers: [
    registerFormInputComponent('product-variant-discount-input',DiscountTypeControl)
  ]
})

export class ProductVariantDiscounttypeControlFieldModule { }