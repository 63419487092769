import { NgModule, Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedModule, CustomFieldControl, CustomFieldConfigType, registerFormInputComponent } from '@vendure/admin-ui/core';


@Component({
  template: `
    <input type="text" [formControl]="formControl" disabled/>
   `
})

export class ReferredCodeControl implements CustomFieldControl {
  readonly: boolean;
  config: CustomFieldConfigType;
  formControl: FormControl;
}

@NgModule({
  imports: [SharedModule],
  declarations: [ReferredCodeControl],
  providers: [
      registerFormInputComponent('referred-form-input',ReferredCodeControl)
  ]
})

export class ReferredCodeControlFieldModule { }