import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritesUiExtensionModule } from './extensions/9f7e04a1ecfcece90c51e3b1e39c2dd3dfd7b5afde34a475bf6e6a863bede95d/favorites-ui-extension.module';
import { SubscriptionExtensionModule } from './extensions/54cc52c0b881fac0661e8b2384b6aecd8e119d6a613d9e69077d234f519c4e9e/subscription-ui-extension.module';
import { VendorExtensionModule } from './extensions/b72a4a4f3aaa937d18a9015d2c944a8bf9220931ac65896c641839cc7fdaa1d6/vendor-ui-extension.module';
import { FeedbackExtensionModule } from './extensions/30a77b9eab9927e7c1ff256a732d8947cb6dfb1361dd47bd89ac4c4a56b555d3/feedback-ui-extension.module';
import { InvoicesNavModule } from './extensions/251f61252b5b213f3d618b57754396e88ee34bf475396b776f672458d8b9cc3a/invoices-nav.module';
import { OrderExportNavModule } from './extensions/c29e4506e91cecae37926c1a2a26bca9b3138d5a9f8721e861927f1da81242ce/order-export-nav.module';
import { ReviewsUiExtensionModule } from './extensions/4370f5c19e89b4a5606a654ac18d3ce01c92a1142fb9d18a0011dad2f13a6de1/reviews-ui-extension.module';
import { WidgetModule } from './extensions/3377111b8e59939deefc7ae5d4e35514b8120f55d3b2b9226feab7b0b659a723/WidgetModule';
import { DistrictFieldModule } from './extensions/9f40f20a32610d14fa12ea5f4ab2b6073ef49f3604f41ac027453a1b913b2d57/district-field';
import { ProductVariantDiscounttypeControlFieldModule } from './extensions/9f40f20a32610d14fa12ea5f4ab2b6073ef49f3604f41ac027453a1b913b2d57/product-variant-discount-field';
import { GlobalSettingsDiscounttypeControlFieldModule } from './extensions/9f40f20a32610d14fa12ea5f4ab2b6073ef49f3604f41ac027453a1b913b2d57/global-settings-discount-field';
import { ReferredCodeControlFieldModule } from './extensions/9f40f20a32610d14fa12ea5f4ab2b6073ef49f3604f41ac027453a1b913b2d57/referred-code-field';
import { VisitNumberFieldControlModule } from './extensions/9f40f20a32610d14fa12ea5f4ab2b6073ef49f3604f41ac027453a1b913b2d57/visitnumber-field';
import { EcommerceDescriptionControlFieldModule } from './extensions/9f40f20a32610d14fa12ea5f4ab2b6073ef49f3604f41ac027453a1b913b2d57/ecommerce-description-field';
import { NavBarHidingModule } from './extensions/9f40f20a32610d14fa12ea5f4ab2b6073ef49f3604f41ac027453a1b913b2d57/navbar-hiding-module';
import { ProductRecommendationsInputModule } from './extensions/3d7d790dd0a8e5f78c2608d24a9e3c7fd2406a53e88aeac7f04e5358763445f2/product-recommendations-input.module';


@NgModule({
    imports: [CommonModule, FavoritesUiExtensionModule, SubscriptionExtensionModule, VendorExtensionModule, FeedbackExtensionModule, InvoicesNavModule, OrderExportNavModule, ReviewsUiExtensionModule, WidgetModule, DistrictFieldModule, ProductVariantDiscounttypeControlFieldModule, GlobalSettingsDiscounttypeControlFieldModule, ReferredCodeControlFieldModule, VisitNumberFieldControlModule, EcommerceDescriptionControlFieldModule, NavBarHidingModule, ProductRecommendationsInputModule],
})
export class SharedExtensionsModule {}
