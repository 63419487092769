import { NgModule, Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedModule, CustomFieldControl, CustomFieldConfigType, registerFormInputComponent } from '@vendure/admin-ui/core';

@Component({
  template: `
	<textarea  [formControl]="formControl" rows="6" cols="30">
    </textarea>
   `
})


export class DescriptionControl implements CustomFieldControl {
  readonly: boolean;
  config: CustomFieldConfigType;
  formControl: FormControl;
}

@NgModule({
  imports: [SharedModule],
  declarations: [DescriptionControl],
  providers: [
     registerFormInputComponent('ecommercedescription', DescriptionControl),
  ]
})

export class EcommerceDescriptionControlFieldModule { }